import { personnelService } from '@/api';
import { PersonnelResource, PositionResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form } from 'element-ui/types/element-ui';
import { Component, Prop } from 'vue-property-decorator';
import { Message } from 'element-ui';
import { SelectPosition } from '@/views/dialogs';
import { emailReg, mobileReg } from '@/utils/validate';
import { cloneDeep } from 'lodash-es';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';
@Component({
  components: { SelectPosition }
})
export default class AddPersonnel extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public personnel!: PersonnelResource | null;

  public positionDialogVisible = false;

  public personnelForm: {
    userName: string;
    realName: string;
    positions: Array<PositionResource>;
    gender: number;
    email: string;
    mobile: string;
    remark: string;
  } = {
    userName: '',
    realName: '',
    positions: [],
    gender: 1,
    email: '',
    mobile: '',
    remark: ''
  };

  public resourceFormRules = {
    userName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('personnel.inputUserName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          personnelService
            .checkPersonnelProp({ userName: value }, this.personnel?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.userNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    realName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('personnel.inputRealName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    positions: [
      {
        required: true,
        validator: (rule: any, value: Array<PositionResource>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('personnel.selectPosition')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    email: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('personnel.inputEmail')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!emailReg(value)) {
            callback(new Error(this.$t('resetPassword.emailError') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          personnelService
            .checkPersonnelProp({ email: value }, this.personnel?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.emailRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    mobile: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('personnel.inputMobile')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!mobileReg(value)) {
            callback(new Error(this.$t('validate.mobileIllegal') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          personnelService
            .checkPersonnelProp({ mobile: value }, this.personnel?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.mobileRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (!this.personnel) {
      this.operationType = 'add';
      this.title = 'personnel.addPersonnel';
      return;
    }
    this.operationType = 'edit';
    this.title = 'personnel.editPersonnel';
    this.$nextTick(() => {
      Object.assign(this.personnelForm, this.personnel);
      this.personnelForm.positions = this.personnel!.positionDTOList || [];
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.personnelForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.personnelForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        const body = {
          ...this.personnelForm,
          positionList: this.personnelForm.positions.map(x => x.id)
        } as any;
        if (this.operationType === 'add') {
          await personnelService.post(body);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await personnelService.put(body);
        Message.success(translation('operationRes.editSuccess'));
        (this.personnelForm as any).positionDTOList = this.personnelForm.positions;
        this.$emit('edit-success', this.personnelForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public selectPosition(): void {
    this.positionDialogVisible = true;
  }

  /**
   * 选择岗位后的回调
   */
  public handleSelectedPositions(selectedPositions: Array<PositionResource>): void {
    this.personnelForm.positions = cloneDeep(selectedPositions);
    (this.$refs.personnelForm as Form).validateField('positions');
  }

  /**
   * 点击岗位的删除按钮时，删除该岗位
   * @param position 岗位
   */
  public handlePositionClose(position: PositionResource): void {
    this.personnelForm.positions = this.personnelForm.positions.filter(x => x.id !== position.id);
  }
}
